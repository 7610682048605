import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { PageDetailComponent } from './@modules/page/components/page-detail/page-detail.component';
import { Page } from './@modules/page/models/page.model';
import { GlobalService } from './Library/services/global.service';
import { PagesService } from './@modules/page/services/pages.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { currentLocale, routes as routesTrans, RouteName } from './Library/routes';
import {SeoItem} from './Library/services/seo/seo-item.model';
import {LocaleService} from './Library/services/locale.service';

@Injectable()
export class PageResolver implements Resolve<Page> {
  constructor(
    private globalService: GlobalService,
    private service: PagesService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Page> | Promise<Page> | any {
    const params = {
      extended_fields: 'modules.slotables,modules.image,modules.slotables.image,image,gallery.media,translations,seo_image,seo_fb_image,seo_tw_image'
    };
    let path = state.url;
    const index = path.indexOf('?');
    if (index > -1) {
      path = path.substring(0, index);
    }
    const token = route.queryParamMap.get('token');
    return this.service.getByPath(path, params, token).pipe(
      tap(res => {
        if ((res as any).redirect) {
          this.globalService.isRedirect.next(res);
        }
      }),
      catchError(error => {
        this.globalService.is404.next(true);
        return of(error);
      }));
  }
}

@Injectable()
export class HomepageResolver implements Resolve<Page> {
  constructor(
    private globalService: GlobalService,
    private service: PagesService,
    private localeService: LocaleService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Page> | Promise<Page> | any {
    const locale = this.localeService.getLocale();
    const params = {
      extended_fields: 'modules.slotables,modules.image,image,modules.slotables.image,gallery.media,translations,seo_image,seo_fb_image,seo_tw_image'
    };
    return this.service.getByPath(locale === 'me' ? 'naslovna' : 'homepage', params).pipe(
      catchError(error => {
        this.globalService.is404.next(true);
        return of(error);
      }));
  }
}


const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: PageDetailComponent,
        resolve: {
          item: HomepageResolver
        },
        data: {
          routeName: RouteName.HOMEPAGE
        }
      },
      {
        path: routesTrans[currentLocale].accommodation,
        loadChildren: () => import('./@modules/accomodation/accomodation.module').then(m => m.AccomodationModule)
      },
      {
        path: routesTrans[currentLocale].contact,
        loadChildren: () => import('./@modules/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: '**',
        component: PageDetailComponent,
        resolve: {
          item: PageResolver
        },
        data: {
          routeName: RouteName.CUSTOM_PAGE,
          reuseRoute: false
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
