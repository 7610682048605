<div
  class="text-brown-900 fixed z-50 flex w-full items-center bg-white sm:relative"
>
  <div class="container">
    <div class="flex h-16 items-center justify-between sm:items-end">
      <button
        [disabled]="disableButton"
        id="menu"
        (click)="toggleMenuDialog()"
        class="menu-button flex sm:hidden"
      >
        <div class="strip strip1"></div>
        <div class="strip strip2" id="strip2"></div>
        <div class="strip strip3"></div>
      </button>
      <div class="mb-2 hidden space-x-3 sm:flex" >
        <a href="https://www.instagram.com/oblunresort" target="_blank">
          <fa-icon [icon]="['fab', 'instagram']" class="flex h-5 w-5"></fa-icon>
        </a>
        <a href="https://www.facebook.com/oblunresort" class="flex h-5 w-5" target="_blank">
          <fa-icon [icon]="['fab', 'facebook']" class="flex h-5 w-5"></fa-icon>
        </a>
        <a href="#" class="flex h-5 w-5 items-center justify-center" target="_blank">
          <fa-icon
            [icon]="['fab', 'youtube']"
            class="flex h-5 w-5 items-center"
          ></fa-icon>
        </a>
      </div>
      <a routerLink="/" class="sm:w-26 h-6 w-16 sm:mb-4 sm:h-10">
        <img
          src="/assets/img/logos/logo.svg"
          alt="Oblun"
          width="180"
          height="57"
          class="h-full w-full"
        />
      </a>
      <div
        class="text-14 relative mb-2 hidden min-w-36 sm:flex sm:items-center sm:justify-center"
      >
        <button
          (click)="openDropdown()"
          class="flex items-center justify-center"
        >
          <span>{{ chosenLanguage === "me" ? "MNE" : "ENG" }}</span>
          <fa-icon
            [icon]="['fas', 'chevron-down']"
            class="ml-3 flex h-4 w-4"
          ></fa-icon>
        </button>

        <div
          *ngIf="languageDropdown"
          class="min-w-30 text-brown-900 absolute right-0 top-0 z-20 flex flex-col border"
        >
          <ul class="border-brown-900 flex flex-col border">
            <li
              [ngClass]="
                chosenLanguage == 'me'
                  ? 'font-500 order-first'
                  : 'font-300 order-last'
              "
            >
              <a
                *ngIf="routeTranslations.me"
                class="hover:bg-brown-100 flex items-center justify-between bg-white px-3 py-2"
                [href]="routeTranslations.me"
              >
                <span>MNE</span>
                <fa-icon
                  *ngIf="chosenLanguage == 'me'"
                  [icon]="['fas', 'chevron-up']"
                  class="ml-3 flex h-4 w-4"
                ></fa-icon>
              </a>
              <a
                *ngIf="!routeTranslations.me"
                class="hover:bg-brown-100 flex items-center justify-between bg-white px-3 py-2"
                (click)="redirectToHome('me')"
              >
                <span>MNE</span>
                <fa-icon
                  *ngIf="chosenLanguage == 'me'"
                  [icon]="['fas', 'chevron-up']"
                  class="ml-3 flex h-4 w-4"
                ></fa-icon>
              </a>
            </li>
            <li
              [ngClass]="
                chosenLanguage == 'en'
                  ? 'font-500 order-fist'
                  : 'font-300 order-last'
              "
            >
              <a
                *ngIf="routeTranslations.en"
                class="hover:bg-brown-100 flex items-center justify-between bg-white px-3 py-2"
                [href]="routeTranslations.en"
              >
                <span>ENG</span>
                <fa-icon
                  *ngIf="chosenLanguage == 'en'"
                  [icon]="['fas', 'chevron-up']"
                  class="ml-3 flex h-4 w-4"
                ></fa-icon>
              </a>
              <a
                *ngIf="!routeTranslations.en"
                class="hover:bg-brown-100 flex items-center justify-between bg-white px-3 py-2"
                (click)="redirectToHome('en')"
              >
                <span>ENG</span>
                <fa-icon
                  *ngIf="chosenLanguage == 'en'"
                  [icon]="['fas', 'chevron-up']"
                  class="ml-3 flex h-4 w-4"
                ></fa-icon>
              </a>
            </li>
          </ul>
        </div>
        <div
          class="fixed inset-0 z-10 bg-transparent"
          *ngIf="languageDropdown"
          (click)="languageDropdown = false"
        ></div>
      </div>
      <div class="w-3/24 sm:hidden"></div>
    </div>
    <div class="bg-brown-900 hidden h-px w-full sm:block"></div>
    <div class="hidden h-12 w-full sm:flex sm:items-center sm:justify-center">
      <ul class="flex h-full">
        <li
          *ngFor="let menuItem of headerMenu"
          routerLinkActive
          #rla="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: true }"
          [ngClass]="
            rla.isActive
              ? 'text-white bg-brown-900 font-500'
              : 'text-brown-900 font-300 hover:bg-brown-100'
          "
          class="leading-175 flex items-center uppercase"
        >
          <a
            [routerLink]="[menuItem.link]"
            class="text-14 h-full w-full px-2 pb-3 pt-4"
          >
            {{ menuItem.title }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>

<!--MOBILE-MENU-->

<div
  [ngClass]="menuTransition ? 'ui-header-fill-screen' : 'h-0'"
  class="text-16 text-brown-900 transition-height fixed top-16 z-20 mb-6 flex w-full flex-col overflow-hidden overflow-y-auto bg-white duration-1000 ease-in-out sm:hidden"
>
  <div class="container">
    <div class="mb-3 h-px w-full bg-gray-400"></div>
  </div>
  <ul *ngIf="menuVisible" class="flex w-full flex-col items-start">
    <li
      *ngFor="let menuItem of headerMenu"
      routerLinkActive
      [routerLinkActiveOptions]="{ exact: true }"
      #rla="routerLinkActive"
      [ngClass]="
        rla.isActive ? 'bg-brown-100 font-500' : 'text-brown-900 font-300'
      "
      class="leading-175 flex w-full items-center capitalize"
    >
      <div class="container flex justify-start text-left">
        <a
          [routerLink]="menuItem.link"
          class="text-18 h-full w-full px-2 py-2"
        >
          {{ menuItem.title }}</a
        >
      </div>
    </li>
  </ul>
  <div *ngIf="menuTransition" class="flex h-full w-full grow items-end">
    <div class="text-brown-900 container mb-9 flex w-full flex-col">
      <span i18n="@@choose_language" class="text-14 font-300 leading-200"
        >Odaberite jezik:
      </span>
      <button
        *ngIf="!languageDropdown"
        (click)="languageDropdown = true"
        class="border-brown-900 flex w-full items-center justify-between border p-2"
      >
        <span>{{ chosenLanguage === "me" ? "MNE" : "ENG" }}</span>
        <fa-icon
          [icon]="['fas', 'chevron-down']"
          class="ml-3 flex h-4 w-4"
        ></fa-icon>
      </button>
      <ul
        *ngIf="languageDropdown"
        class="border-brown-900 flex flex-col border"
      >
        <li></li>
        <li
          [ngClass]="
            chosenLanguage == 'me'
              ? 'font-500 order-first'
              : 'font-300 order-last'
          "
        >
          <a
            *ngIf="routeTranslations.me"
            class="hover:bg-brown-100 flex items-center justify-between bg-white px-3 py-2"
            [href]="routeTranslations.me"
          >
            <span>MNE</span>
            <fa-icon
              *ngIf="chosenLanguage == 'me'"
              [icon]="['fas', 'chevron-up']"
              class="ml-3 flex h-4 w-4"
            ></fa-icon>
          </a>
          <a
            *ngIf="!routeTranslations.me"
            class="hover:bg-brown-100 flex items-center justify-between bg-white px-3 py-2"
            (click)="redirectToHome('me')"
          >
            <span>MNE</span>
            <fa-icon
              *ngIf="chosenLanguage == 'me'"
              [icon]="['fas', 'chevron-up']"
              class="ml-3 flex h-4 w-4"
            ></fa-icon>
          </a>
        </li>
        <li
          [ngClass]="
            chosenLanguage == 'en'
              ? 'font-500 order-fist'
              : 'font-300 order-last'
          "
        >
          <a
            *ngIf="routeTranslations.en"
            class="hover:bg-brown-100 flex items-center justify-start bg-white px-3 py-2"
            [href]="routeTranslations.en"
          >
            <span>ENG</span>
            <fa-icon
              *ngIf="chosenLanguage == 'en'"
              [icon]="['fas', 'chevron-up']"
              class="ml-3 flex h-4 w-4"
            ></fa-icon>
          </a>
          <a
            *ngIf="!routeTranslations.en"
            class="hover:bg-brown-100 flex items-center justify-between bg-white px-3 py-2"
            (click)="redirectToHome('en')"
          >
            <span>ENG</span>
            <fa-icon
              *ngIf="chosenLanguage == 'en'"
              [icon]="['fas', 'chevron-up']"
              class="ml-3 flex h-4 w-4"
            ></fa-icon>
          </a>
        </li>
      </ul>
      <div class="mt-8 h-px w-full bg-gray-400"></div>
      <div class="mt-6 flex items-center justify-center">
        <a
          href="https://www.instagram.com/oblunresort"
          class="text-24 flex h-12 w-12 items-center justify-center"
          target="_blank"
        >
          <fa-icon
            [icon]="['fab', 'instagram']"
            class="flex h-8 w-8 items-center"
          ></fa-icon>
        </a>
        <a
          href="https://www.facebook.com/oblunresort"
          class="text-24 flex h-12 w-12 items-center justify-center"
          target="_blank"
        >
          <fa-icon
            [icon]="['fab', 'facebook']"
            class="flex h-8 w-8 items-center"
          ></fa-icon>
        </a>
        <a href="#" class="text-24 flex h-12 w-12 items-center justify-center" target="_blank">
          <fa-icon
            [icon]="['fab', 'youtube']"
            class="flex h-8 w-8 items-center"
          ></fa-icon>
        </a>
      </div>
    </div>
  </div>
</div>
