import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { faTwitter, faInstagram, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { LocaleService } from '../../../Library/services/locale.service';
import { filter, take, tap } from 'rxjs/operators';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import {currentLocale, routes} from '../../../Library/routes';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  currentYear: number;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faLinkedinIn = faLinkedinIn;
  faFacebookF = faFacebookF;
  locale: string;
  apiParams: any;
  footerMenu: MenuItem[];
  showChildren: boolean[] = Array<boolean>(10);
  openedChildIndex: number = -1;
  sempahores: any = {
    loading: true
  };
  aboutUs = `/${routes[currentLocale].about}`;
  contact = `/${routes[currentLocale].contact}`;

  constructor(
    private localeService: LocaleService,
    private detailService: DetailService,
    @Inject(LOCALE_ID) public localeCode: string,
  ) {
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {

    this.sempahores.loading = true;
    for (let i = 0; i < this.showChildren?.length; i++) {
      this.showChildren[i] = true;
    }
    this.currentYear = new Date().getFullYear();
    this.detailService.details$.pipe(
      filter(result => result != null),
      tap(result => {
        this.footerMenu = result.additional.menus.footer.items;
        this.showChildren = Array<boolean>(this.footerMenu?.length);
        for (let i = 0; i < this.showChildren?.length; i++) {
          this.showChildren[i] = false;
        }
        this.sempahores.loading = false;
      }),
      take(1)
    ).subscribe();
  }

  updateChildren(index: number): void {
    if (this.openedChildIndex !== -1 && !this.showChildren[index]) {
      this.showChildren[this.openedChildIndex] = false;
      this.openedChildIndex = index;
    } else if (this.openedChildIndex === -1 && !this.showChildren[index]) {
      this.openedChildIndex = index;
    }
    this.showChildren[index] = !this.showChildren[index];
  }
}
