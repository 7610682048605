<footer class="bg-brown-900 text-white pt-6 sm:pt-12 sm:pb-9">
  <div class="container flex">
    <div class="w-full flex flex-col sm:flex-row">
      <div
        class="w-full sm:w-4/24 h-full flex flex-col justify-center sm:justify-start items-center pb-8"
      >
        <a routerLink="/" class="w-full flex justify-center sm:justify-start">
          <img
            src="/assets/img/logos/logo_footer.svg"
            class="w-25 sm:w-32 h-10 sm:h-13"
            alt="Oblun"
          />
        </a>
        <span
          class="w-full text-left text-white text-16 font-500 leading-175 mt-8"
          >OBLUN d.o.o.</span
        >
        <span
          class="w-full mt-1 font-300 text-12 leading-130 Podgorica, Montenegro"
          >Podgorica, Montenegro</span
        >
        <a
          href="mailto:info@oblun.com"
          class="w-full text-left mt-2 font-300 text-12 leading-130"
          >info@oblun.com</a
        >
        <a
          [routerLink]="aboutUs"
          class="w-full text-left mt-9 text-14 font-300 leading-170"
          i18n="@@about_us"
          >O nama</a
        >
        <a
          [routerLink]="contact"
          class="w-full text-leaft mt-2 text-14 font-300 leading-170"
          i18n="@@contact_us"
          >Kontaktirajte nas</a
        >
      </div>

      <!--      DESKTOP LIST-->

      <ul class="hidden sm:flex w-full sm:w-18/24 flex-col sm:flex-row">
        <li
          *ngFor="let menuItem of footerMenu"
          class="flex flex-col w-full sm:w-6/24 gap-4"
        >
          <a
            [routerLink]="[menuItem.link]"
            class="uppercase font-500 text-16 leading-175"
            >{{ menuItem.title }}</a
          >
          <ul class="flex flex-col space-y-2">
            <li
              *ngFor="let item of menuItem.items"
              class="text-14 leading-170 font-300"
            >
              <a [routerLink]="[item.link]">{{ item.title }}</a>
            </li>
          </ul>
        </li>
      </ul>

      <!--      MOBILE LIST-->

      <ul
        *ngIf="!sempahores.loading"
        class="flex flex-col sm:hidden w-full mb-8"
      >
        <div class="w-full bg-white h-px"></div>
        <li *ngFor="let menuItem of footerMenu; let i = index" class="w-full">
          <button
            (click)="updateChildren(i)"
            [ngClass]="{ 'pb-4': showChildren[i] }"
            class="w-full flex justify-between items-center py-3"
          >
            <span
              class="font-700 uppercase text-white text-16 font-500 leading-175"
              >{{ menuItem.title }}</span
            >
            <fa-icon
              *ngIf="showChildren[i]"
              [icon]="['fal', 'chevron-up']"
              class="w-4 h-4 text-white flex justify-center items-center"
            ></fa-icon>
            <fa-icon
              *ngIf="!showChildren[i]"
              [icon]="['fal', 'chevron-down']"
              class="w-4 h-4 text-white flex justify-center items-center"
            ></fa-icon>
          </button>
          <ul *ngIf="showChildren[i]" class="w-full flex flex-col">
            <li *ngFor="let item of menuItem.items" class="mb-2">
              <a
                [routerLink]="item.link"
                class="w-full font-300 text-14 leading-170"
                >{{ item.title }}</a
              >
            </li>
          </ul>
          <div *ngIf="!showChildren[i]" class="w-full bg-white h-px"></div>
        </li>
      </ul>
    </div>
  </div>
  <div class="container">
    <div class="hidden sm:flex w-full bg-white h-px"></div>
    <div class="w-full flex justify-between items-center">
      <div class="flex flex-col mt-4">
        <span
          class="hidden sm:flex text-gray-400 font-500 text-10 leading-160"
          i18n="@@all_rights_reserved"
        >
          © Oblun Resort d.o.o {{ currentYear }}. Sva prava zadržana.
        </span>
        <div class="mt-3 flex flex-col sm:flex-row">
          <span class="font-500 text-12 leading-130" i18n="@@privacy_politics"
            >Politika privatnosti</span
          >
          <span
            class="font-500 text-12 leading-130 sm:ml-4 mt-2 sm:mt-0"
            i18n="@@contidions_terms"
            >Uslovi korišćenja</span
          >
        </div>
      </div>

      <div class="flex justify-center items-center mt-4 space-x-4">
        <a
          href="https://www.instagram.com/oblunresort"
          class="text-24 flex justify-center items-center w-6 h-6"
          target="_blank"
        >
          <fa-icon
            [icon]="['fab', 'instagram']"
            class="w-6 h-6 flex items-center"
          ></fa-icon>
        </a>
        <a
          href="https://www.facebook.com/oblunresort"
          class="text-24 flex justify-center items-center w-6 h-6"
          target="_blank"
        >
          <fa-icon
            [icon]="['fab', 'facebook']"
            class="w-8 h-8 flex items-center"
          ></fa-icon>
        </a>
        <a href="#" class="text-24 flex justify-center items-center w-6 h-6" target="_blank">
          <fa-icon
            [icon]="['fab', 'youtube']"
            class="w-6 h-6 flex items-center"
          ></fa-icon>
        </a>
      </div>
    </div>
    <span
      class="w-full flex justify-center sm:hidden text-gray-400 font-500 text-10 pt-14 pb-6 text-center leading-160"
      i18n="@@all_rights_reserved"
    >
      © Oblun Resort d.o.o {{ currentYear }}. Sva prava zadržana.
    </span>
  </div>
</footer>
